.search-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;

  .search-bar-container {
    display: flex;
    align-items: center;
    background: rgba(80, 74, 87, 1) !important;
    width: 65%;
    height: 40px;
    border-radius: 10px;
    padding: 10px;

    .search-icon {
      margin-right: 8px;
    }

    .search-input {
      color: white;
      background-color: transparent;
      border: none;
      outline: none; // Quitar el borde de color azul al hacer clic
      width: 100%; // Ocupar todo el ancho disponible

      &:focus {
        outline: none;
      }
    }

    &:focus-within {
      .search-input {
        border: none;
      }
    }
  }

  .circular-button {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    background: linear-gradient(to right, #f600c0 0%, #4409a4 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
  }

  .circular-button:active,
  .circular-button:focus {
    outline: none;
  }

  .circular-button .plus-icon {
    font-size: 65px;
    color: white;
  }
}

.table-container {
  width: 100%;
  background-color: rgba(73, 9, 164, 0.18);

  .table-header,
  .table-row {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .table-header {
    font-size: 14px;
    color: #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
  }

  .table-row {
    font-size: 13px;
    color: #f4f4f4;
  }

  .column {
    flex: 1;
    padding: 8px;
    display: flex;
    align-items: center;
    word-wrap: break-word;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 50px;
    flex-direction: row;
    justify-content: center;
    padding: 0px 0px;
    text-align: center;

    .container-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .CheckInImg {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      align-content: center;
      margin: 5px;
      img {
        border-radius: 50%;
        margin: 0px;
        height: 30px;
        width: 30px;
        object-fit: cover;
      }
      .ant-image-mask:hover {
        border-radius: 50%;
      }
      .ant-image-mask {
        transition: opacity 0.3s, boder-radius 0.3s;
      }
    }
  }
}

.pagination {
  display: flex;
  align-items: center;

  .arrow {
    color: white;
  }

  .page-number {
    margin: 0 10px;
    color: white;
  }
}

.column.actions {
  flex-grow: 2;
}

.column.DNI {
  flex-basis: 35px;
}
