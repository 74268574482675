.receptionAdminContainer {
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  padding: 0rem 1rem;
  .title {
    margin-bottom: 20px;
    p {
      font-size: 44px;
      font-weight: bold;
    }
  }
}
