// style.scss
.switch-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  .label {
    color: white;
    margin-bottom: 5px;
    margin-left: 15px;
    margin-right: 10px;
  }
}
