.custom-button {
  background: linear-gradient(to left, #f600c0 0%, #4409a4 100%);
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  font-weight: bold;
  line-height: 1.5;
  font-size: 1rem;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &.cancel {
    background: none;
    border: 0.5px solid white;
  }
  &.disable {
    background: linear-gradient(to left, #382d2d 0%, #2a1d47 100%);
    border: 0.5px solid rgb(0, 0, 0);
    cursor: auto;
  }
}
