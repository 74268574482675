.input-section {
  //   flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .containerInputs {
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
    gap: 1rem;
  }
}
