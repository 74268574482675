.containerModalCamera-CheckInCenter {
  margin: 0.5rem;
  h3 {
    width: 100%;
    border-bottom: 3px solid #4409a4;
  }

  .modal-sections-CheckInCenter {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 280px;
    gap: 1rem;
    .container-Inputs-CheckInCenter {
      width: 23%;
    }

    .container-cameras-CheckInCenter {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 20px;
      justify-content: space-evenly;

      .camera-section-RTSP {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 48%;
        max-width: 330px;

        .textoCamera-RTSP {
          top: 0px;
          text-align: center;
          width: 100%;
          font-weight: bold;
          background: black;
          margin: 0px;
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding: 4px 0px;
          color: white;
        }
      }

      .capture-button-RTSP {
        left: 50%;
        transform: translateX(-50%);
        background: black;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 5px;
        cursor: pointer;
        color: white;
        min-height: 35px;
      }

      canvas,
      img {
        object-fit: fill;
        width: 100%;
        height: 65%;
        margin: 0px;
        background: black;
      }
    }

    .button-section-CheckInCenter {
      width: 27%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      margin: 0px 10px;
      flex-wrap: wrap;
      flex-direction: column;

      .modal-laterals-header {
        display: flex;
        height: 50%;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        .textHeader {
          font-size: 1.3rem;
          padding-right: 10px;
          float: left;
        }

        svg,
        img {
          font-size: 5rem;
          margin: 2px;
          height: 5rem;
        }

        .stylesImgsvg {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;

          .circle {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              color: white;
              font-weight: bold;
            }
          }
          .green {
            background-color: rgb(0, 248, 0);
          }
          .yellow {
            background-color: rgb(216, 250, 0);
            p {
              color: black;
            }
          }
          .red {
            background-color: rgb(222, 16, 16);
          }
        }

        .stylesImgQR {
          display: flex;
          align-items: center;
          p {
            margin: 1rem;
            font-size: 3rem;
          }
          img {
            font-size: 4rem;
            margin: 0;
            height: 100%;
          }
          svg {
            font-size: 3rem;
          }
        }
      }

      span.anticon.anticon-clock-circle {
        font-size: 7rem;
      }

      .custom-button {
        background: none !important;
        padding: 0 !important;

        svg {
          font-size: 11rem;
          color: gray;
        }

        .my-icon {
          width: 100%;
          /* Ocupa el 100% del ancho del contenedor */
          height: 100%;
          /* Ocupa el 100% del alto del contenedor */
        }

        .my-icon .gradient-fill path {
          fill: url(#gradient);
          /* Aplicar el gradiente al path del ícono */
        }
      }

      .modal-laterals-foother {
        text-align: center;
      }
    }
  }
}

canvas,
img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
