.containerModalCamera {
  display: flex;
  flex-direction: column;
  height: 100%;
  .containerTitle {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    height: 10%;
    .leftSection {
      margin-left: 10px;
    }
    .middleSection {
      flex-grow: 1;
      text-align: center;
      color: white;
    }
  }
  .modal-sections {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 90%;
    .modal-section {
      display: flex;
    }
    .button-section {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      padding: 10px;
    }
  }
}
