.staffContainer {
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  color: #f4f4f4;
  display: flex;
  width: 95%;
  overflow: auto;
  background: #2a1744;
  flex-direction: column;
  margin: 0px auto;
}

.title {
  margin-bottom: 20px;
  h4 {
    font-size: 44px;
    font-weight: bold;
    margin-top: 25px;
    margin-left: 25px;
  }
}
