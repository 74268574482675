.image-upload-container {
  .input-label {
    color: white;
    margin-bottom: 5px;
    margin-left: 15px;
  }
  .image-upload {
    border: 2px dashed #ccc;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    position: relative;

    &.dragging {
      background-color: #f0f0f0;
    }

    p {
      color: white;
    }

    .image-container {
      max-height: 200px;
      overflow: hidden; /* Ocultar cualquier contenido que exceda la altura máxima */
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .uploaded-image {
        max-width: 100%;
        max-height: 200px;
        margin: 0 auto;
        display: block;
      }

      .close-button {
        position: absolute;
        font-size: 20px;
        background: none;
        border: none;
        cursor: pointer;
        color: white;
        font-weight: bold;
        right: 0px;
        top: 0px;
      }
    }
  }
}
