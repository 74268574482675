.checkPointsContainer {
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  padding: 20px;
}

.titleCheckPoints h4 {
  margin-bottom: 0px;
  font-size: 40px;
}

.container-Download-Checkpoint {
  height: 50px;
}
