.routinesContainer {
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  background: rgb(32, 26, 41);
  flex-direction: column;
  align-content: center;
  padding: 0rem 2rem;

  .title {
    margin-bottom: 20px;
    p {
      font-size: 44px;
      font-weight: bold;
      margin-top: 25px;
    }
  }
}
