.input-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .icon-input-container {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 40px;
    padding: 15px;

    .icon {
      margin-right: 0.5rem;
      width: 15px;
      height: 15px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .input-element {
      border: none;
      font-size: 1rem;
      width: 100%;
      outline: none;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid transparent;
      pointer-events: none;
    }
  }
  .selected {
    border-style: solid;
    border-width: 2px;
    border-color: black;
  }
}
