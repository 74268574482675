.popup-overlay-resident {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup {
    background: rgb(38, 26, 58);
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h3 {
    margin-bottom: 15px;
  }
  .itemsContainerResident {
    display: flex;
    flex-direction: row;
    margin: 5px;
    align-items: center;
    justify-content: space-around;
  }
  .itemsContainer {
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 5px;
    align-items: center;
    justify-content: space-around;
  }
}
