.paymentsDetail-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  .containerphoto {
    width: 40%;
    .photo {
      width: 35%;
      height: 85%;
      position: absolute;
      top: 7%;
      img {
        object-fit: cover;
        border-radius: 10%;
      }
    }
  }

  .inf {
    height: 95%;
    width: 65%;
    padding: 0 3rem;

    .database-container {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      border-color: #5a1fd1;
      border-bottom-style: inset;

      .data {
        text-align-last: left;
        p {
          font-size: 1.2rem;
          width: 100%;
          line-height: 1.7rem;
        }
        p:last-child {
          font-size: 1rem;
          padding-top: 7px;
        }
      }
      .status {
        width: 25%;
        svg {
          font-size: 7rem;
        }
      }
    }

    .statement {
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
      margin: 1.2rem 0;
      gap: 0.5rem;
      height: 35%;
      overflow: overlay;

      .detail {
        display: grid;
        grid-template-columns: 2fr 4fr 4fr 2fr 1fr;
        width: 100%;
        align-items: center;
        justify-items: center;
        text-align: center;
        align-content: center;
        position: relative;
      }
    }
    .totalStatement {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      flex-wrap: nowrap;
      align-items: baseline;
      gap: 7rem;
      border-color: #5a1fd1;
      border-top-style: outset;
    }

    .actions {
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      bottom: 4rem;
      margin-top: 5rem;
      .custom-button {
        padding: 10px 7px;
      }
    }
  }
}
