.camera-section {
  position: relative;
  margin: 30px;
  width: 33%;
  overflow: hidden;
  .textoCamera {
    position: absolute;
    top: 0px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    background: black;
    margin: 0px;
    width: 100%;
    padding: 4px 0px;
    color: white;
  }
  video,
  image {
    object-fit: cover;
  }
  .capture-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: black;
    border: none;
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    color: white;
  }
}
