.residentContainer {
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  overflow: hidden;
  background: rgb(32, 26, 41);
  flex-direction: column;
  align-content: center;
  padding: 0rem 2rem;

  .title {
    margin-bottom: 20px;
    p {
      font-size: 44px;
      font-weight: bold;
      margin-top: 25px;
    }
  }
  .actionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .enableContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 4px;
      font-size: large;
    }
  }
}

.green {
  color: rgb(8, 239, 62);
}
.red {
  color: rgb(239, 16, 8);
}

.containerDelegatesQuantity {
  width: 100%;
}
