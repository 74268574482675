.input-container-dark {
  display: flex;
  flex-direction: column;

  cursor: pointer;

  .input-label {
    color: white;
    margin-bottom: 5px;
    margin-left: 15px;
  }

  .icon-input-container {
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: rgba(56, 78, 183, 0.3);
    border: #484747 1px solid;
    border-radius: 20px;
    .icon {
      width: 15px;
      height: 15px;
      margin-right: 0.5rem;
    }

    .input-element {
      flex: 1;
      border: none;
      font-size: 1rem;
      background-color: transparent;
      color: white;
      outline: none;
    }
    &:hover {
      border-color: white;
    }
  }

  .input-element::placeholder {
    color: #f4f4f4cf;
  }

  &.selectedInput {
    border-color: white;
  }
}
