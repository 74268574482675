.CredentialsContainer {
  height: 20%;
  margin-top: 100px;
  width: 90%;
  margin: 10% auto;
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 5px solid #9b137f;

    p {
      font-size: 32px;
      font-weight: bold;
    }
    svg {
      font-size: 2rem;
    }
  }
  .ListContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .data {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      align-items: center;
    }
    p {
      font-size: 20px;
    }
  }
}

.button {
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 170px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.disable {
    cursor: default;
  }
  div {
    display: flex;
    height: -webkit-fill-available;
    align-items: center;
    margin: 3px;
    font-size: larger;
  }
}

.CredentialModal {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0px 40px;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  .border {
    height: 50px;
    width: -webkit-fill-available;
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
  }

  .labelCredentials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .input {
      width: 80%;
      padding: 0px 11px;
      font-size: 18px;
    }
    p {
      font-size: 14px;
      line-height: 1rem;
    }
    svg {
      color: white;
    }
  }

  input:-webkit-autofill {
    background-color: transparent !important; /* Fondo transparente o personalizado */
    color: inherit !important; /* Conserva el color del texto */
    -webkit-box-shadow: 0 0 0px 1000px var(--ant-color-bg-container) inset !important; /* Fondo según Ant Design */
    box-shadow: 0 0 0px 1000px var(--ant-color-bg-container) inset !important;
    border-color: inherit !important; /* Asegúrate de mantener el borde original */
  }

  /* Compatibilidad con otros navegadores */
  input:-moz-autofill {
    background-color: transparent !important;
    color: inherit !important;
  }

  input:-ms-autofill {
    background-color: transparent !important;
    color: inherit !important;
  }
}
