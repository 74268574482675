// styles.scss

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .elementsContainer {
    width: 90%;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .formElement {
      width: calc(45% - 10px);
      margin-bottom: 20px;
    }
  }

  .button {
    width: 90%; // Ajusta el ancho del botón según tus preferencias
    max-width: 400px; // Establece el ancho máximo para el botón
    align-self: center;
  }
}
