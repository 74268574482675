.reportPage {
  padding-top: 2rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  color: #f4f4f4;
  display: flex;
  background: rgb(32, 26, 41);
  flex-direction: column;
  overflow-x: hidden;
  .containerTitle {
    display: flex;
    align-items: center;
    .title {
      font-size: 44px;
      font-weight: bold;
    }
  }
  .containerFilters {
    display: flex;

    width: 100%;
    .containerInputFilters {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-around;
      align-items: center;

      .datePicker {
        background-color: rgba(56, 78, 183, 0.3);
        border-radius: 20px;
        label,
        input,
        svg {
          color: white !important;
        }
      }
      .datePicker,
      .inputDark {
        width: 48%;
      }
    }
    .containerButtonsFilters {
      margin: 10px;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .multiSelectAnt {
      .ant-select-selector {
        border-radius: 20px;
        height: 52px;
      }
      span {
        color: white !important;
      }
    }
  }
}
