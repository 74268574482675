.StatementResident {
  width: 100%;
  padding: 20px;
  .title {
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    position: relative;
    border-bottom: 2px solid #5a1fd1;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: nowrap;
    .svg {
      position: absolute;
      left: -32px;
      top: -10px;
    }

    p {
      width: 100%;
      text-align: start;
      font-weight: 700;
      font-size: 16px;
      color: white;
    }

    .svgPlus {
      .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
        padding: 0px !important;
      }
    }
  }

  .containerSlider {
    margin: 15px 0px;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    transition: max-height 0.5s linear 0s;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: baseline;
  }

  .containerMap {
    margin: 5px 0px;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    gap: 1rem;
    .details {
      width: 100%;
      display: grid;
      align-items: baseline;
      align-content: center;
      justify-content: center;
      justify-items: start;
    }
  }

  .totalPrice {
    border-top: 2px solid #5a1fd1;
    margin: 0px 0px 25px 0px;
    padding: 10px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
  }

  .PriceContainer {
    width: 80px;
    text-align: end;
    margin: auto 0;
  }
}

.containerSetCuota {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: stretch;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  gap: 2rem;
  .title {
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    position: relative;
    border-bottom: 2px solid #5a1fd1;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: nowrap;

    p {
      width: 100%;
      text-align: start;
      font-weight: 700;
      font-size: 16px;
      color: white;
    }
  }

  .ButtonCuota {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
