.select-container2 {
  border-color: #5a1fd1;
  background-color: #14113f;
  border-radius: 11px;
  font-size: 14px;
  margin-bottom: 5px;
}

.input-label2 {
  color: white;
}

.select-element2 {
  cursor: pointer;
  border-radius: 11px;
  height: 30px;
  background-color: #14113f;
  width: 100px;
  text-align: center;
  color: white;
  appearance: none;
  option {
    background-color: #14113f;
    color: white;
  }
}
