$primary-color: #f600c0;
$secondary-color: #4409a4;
$sidebar-width: 280px;
$collapsed-sidebar-width: 80px;
$border-radius: 40px;

.sidebar {
  width: $sidebar-width;
  height: 100%;
  background: linear-gradient($primary-color, $secondary-color);
  border-top-right-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: width 0.3s;
  position: fixed;

  &.open {
    width: $collapsed-sidebar-width;
  }

  .header {
    width: 100%;
    height: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .logo {
      max-width: 80%;
      max-height: 80%;
    }

    .burger {
      display: block;
      position: absolute;
      left: 20px;
      cursor: pointer;
      z-index: 1;
      &.hamburguer {
        position: initial;
      }
    }
  }

  .menuItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding-top: 20px;
    padding-left: 5%;
    padding-right: 5%;
    &.hamburguer {
      padding-left: 0%;
      padding-right: 0%;
      .menuItem {
        justify-content: center;
      }
    }
    .menuItem {
      display: flex;
      align-items: center;
      padding: 10px;
      color: white;
      cursor: pointer;
      text-decoration: none;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;

      .icon {
        margin-right: 10px;
        width: 20px;
      }

      span {
        transition: opacity 0.3s;
        opacity: 1;
      }

      &.collapsed {
        span {
          opacity: 0;
        }
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .submenu {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        .submenuItem {
          display: flex;
          align-items: center;
          padding: 8px 10px 0px;
          color: white;
          cursor: pointer;
          text-decoration: none;
          width: 100%;
          justify-content: space-between;
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    padding: 20px;
    span {
      margin-left: 20px;
    }
  }
}
