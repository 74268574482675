.ContainerCorp {
  h2 {
    color: white;
    margin-bottom: 10px;
  }
}

.multi-select-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 20px;
  padding: 2rem;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.select-item.selected {
  background-color: #ffffff;
  border: 3px solid #05a660;
}
.select-item {
  padding: 10px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  background-color: #ffffffc9;
  cursor: pointer;
  height: 50px;
  text-align: center;
  align-content: center;
  color: darkviolet
}
