.newClientForm {
  h2 {
    color: white;
    margin-bottom: 10px;
  }
}
.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
