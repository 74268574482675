.qr-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    margin-bottom: 15px;
    color: white;
  }
  .qr-code-container {
    margin-bottom: 15px;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}
