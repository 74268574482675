.date-picker-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .input-label {
    color: white;
    margin-bottom: 5px;
    margin-left: 15px;
  }

  .date-picker-wrapper {
    background-color: rgba(56, 78, 183, 0.3);
    border: #484747 1px solid;
    border-radius: 20px;
    display: flex;
    flex: 1;
    align-items: center;

    .date-picker-element {
      width: 100%;
      background-color: transparent;
      color: white;
      .ant-picker {
        width: 100%;
        background-color: transparent;
        border: none;
        color: white;
      }
    }

    .ant-picker-selection-placeholder {
      color: white !important;
      font-weight: inherit !important;
      font-size: 14px !important;
    }
  }
}
