.loading-icon {
  display: block;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #333;
  animation: spin 1s linear infinite;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
}
