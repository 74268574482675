.image-upload {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  position: relative;

  &.dragging {
    background-color: #f0f0f0;
  }

  p {
    color: white;
  }
  .image-container {
    img.uploaded-image {
      max-width: 100%;
      max-height: 200px;
      margin-top: 10px;
      position: relative;
    }
    .close-button {
      position: absolute;
      font-size: 10px;
      background: none;
      border: none;
      cursor: pointer;
      color: white;
      font-weight: bold;
      right: 15px;
      top: 15px;
    }
  }
}
