.newResidentForm {
  h2 {
    margin: auto;
    margin-bottom: 15px;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
  }
}

.formContainerRoutines {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .elementsContainerRoutines {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .formElementRoutines {
      width: 32%;
      margin-bottom: 20px;
    }

    .formElementRoutines.DragAndDrop {
      width: 100%;
    }
    .formElementRoutines.search {
      width: 100%;
    }
  }
}

.form-search-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-search-bar-container {
    display: flex;
    width: 45%;
    height: 50px;
    align-items: center;
    background-color: rgba(56, 78, 183, 0.3);
    border-radius: 10px;
    padding: 15px;

    .form-search-icon {
      margin-right: 8px;
    }

    .form-search-input {
      color: white;
      background-color: transparent;
      border: none;
      outline: none; // Quitar el borde de color azul al hacer clic
      width: 100%; // Ocupar todo el ancho disponible

      &:focus {
        outline: none;
      }
    }

    &:focus-within {
      .search-input {
        border: none;
      }
    }
  }
}
