.ant-notification-notice-icon {
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 30px;
    left: 12px;
    p {
      color: white;
      font-weight: bold;
    }
  }
  .green {
    background-color: rgb(0, 248, 0);
  }
  .red {
    background-color: rgb(255, 0, 0);
  }
}

.SocketsreactQR {
  .containerBan {
    color: aliceblue;
    h3 {
      font-size: 20px;
      font-weight: 900;
      line-height: 1;
      text-align: center;
      letter-spacing: 1px;
    }

    .containerData {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: nowrap;
      justify-content: space-around;
    }
    .timeTapReadQr {
      position: fixed;
      font-size: 10px;
      bottom: 5px;
      right: 15px;
    }
  }

  .anticon {
    color: antiquewhite;
  }
}

.SocketsreactQR:ant-notification-notice-wrapper {
  border-radius: 22px;
}

.ant-notification .ant-notification-notice-wrapper {
  border-radius: 30px !important;
}
.ant-notification-notice-progress {
  height: 6px !important; /* Cambia este valor a la altura deseada */
}
