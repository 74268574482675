h4 {
  margin-bottom: 0px;
  font-size: 40px;
}

.DashboardContainer {
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  padding: 20px;
}

.infoGeneralContainer {
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  margin: 1rem 2rem;
  padding: 1rem 0rem 1rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
}

.generalInf {
  list-style: none;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin: 0px 10px;
  }
}

.infStaffs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 2rem 2rem;
}

.infStaff {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 10rem;
  justify-content: space-around;
  p {
    font-size: 18px;
  }
}

.barStatus {
  margin-top: 4rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
}

.tickets-container {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  gap: 20px;
}

.ticket {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border: 1px solid #14113f;
  border-radius: 5px;
  background-color: #261a3a;
  justify-content: space-between;
}

.ticket-info {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 85%;
  align-items: center;
  justify-items: center;
  text-align: center;
  align-content: center;
  position: relative;
}

.ticket-status,
.ticket-id,
.ticket-priority,
.ticket-shortID,
.ticket-description,
.ticket-staff,
.ticket-time {
  font-size: 14px;
}

.ticket-description {
  width: 100%;
  text-align: center;
}

.ticket-photo {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}
.ticket-actions {
  width: 170px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}
.ticket-actions button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: white;
}

.ticket-actions button:hover {
  background-color: #5a1fd1;
}

.tooltip .tooltip-content {
  visibility: hidden;
  display: inline-block;
  padding: 10px;
  background-color: #333;
  color: #fff;
  position: absolute;
  z-index: 1;
  margin-top: 20px;
}

.tooltip:hover .tooltip-content {
  visibility: visible;
}

.ticket-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border: 1px solid #261a3a;
  border-radius: 11px;
  background-color: #14113f;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.ticket-info-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 85%;
  align-items: center;
  justify-items: center;
  align-content: center;
}

.dashboard-search-section {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  margin: 1rem 0rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
}

.dashboard-search-bar-container-dashboard {
  display: flex;
  align-items: center;
  background: rgb(80, 74, 87) !important;
  width: 20%;
  height: 30px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
  margin-left: auto;

  .dashboard-search-icon {
    margin-right: 0px;
  }

  .dashboard-search-input {
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &:focus-within {
    .dashboard-search-input {
      border: none;
    }
  }
}

.custom-tooltip {
  height: 600px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes colorChangeOpen {
  0% {
    color: #4d4dff; /* Azul */
  }

  50% {
    color: #fc6e22; /* Rojo */
  }

  100% {
    color: #4d4dff; /* Azul */
  }
}
.dashBoardOpen {
  animation: spin 2s linear infinite, colorChangeOpen 1s ease-in-out infinite;
}

@keyframes colorChangeClose {
  0% {
    box-shadow: 0 0px 0px 0px green;
  }

  50% {
    box-shadow: 0 0px 5px 0px green;
  }

  100% {
    box-shadow: 0 0px 0px 0px green;
  }
}

.dashBoardClose {
  animation: colorChangeClose 1s ease-in-out infinite;
  color: green;
}

@keyframes colorChangeHold {
  0% {
    box-shadow: 0 0px 0px 0px #ccff00;
  }

  50% {
    box-shadow: 0 0px 20px 1px #ccff00;
  }

  100% {
    box-shadow: 0 0px 0px 0px #ccff00;
  }
}

.dashBoardHold {
  color: #ccff00;
  border-radius: 50%;
  animation: colorChangeHold 1s ease-in-out infinite;
}

.dashBoardEvaluating {
  color: #ff4800;
  box-shadow: 0 0px 15px 0px #ff4800;
  border-radius: 50%;
}

.dashBoardSpam {
  color: #ff0033;
}

.icon-positive {
  color: green;
}

.icon-negative {
  color: red;
}

.iconSvg {
  font-size: 30px !important; /* O usa un tamaño específico si prefieres */
}
