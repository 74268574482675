$sidebar-width: 280px;
$collapsed-sidebar-width: 80px;

.layoutContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
  background-color: #2a1744;
}
.mainContainer {
  width: 100%;
  height: 100%;
  margin-left: $sidebar-width;
  transition: all 0.5s;
  overflow: overlay;
  padding: 0 1rem;
}

.fullMainContainer {
  width: 100%;
  height: 100%;
  margin-left: $collapsed-sidebar-width;
  transition: all 0.5s;
  overflow: overlay;
  padding: 0 1rem;
}

@media print {
  body {
    margin: 0;
    padding: 0;
    background-color: #fff; /* Cambia el color de fondo si es necesario */
  }

  #qr-Printer {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
