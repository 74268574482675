.popup-overlay-confirm {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup {
    padding: 63px 20px;
    background: rgb(38, 26, 58);
    border-radius: 4px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 400px;

    .regexi {
      color: #05a660;
      font-size: 25px;
      font-weight: bold;
    }

    p {
      color: white;
      margin-bottom: 20px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
    }

    .accept-button,
    .reject-button {
      background: linear-gradient(to right, #f600c0 0%, #4409a4 100%);
      border: none;
      border-radius: 4px;
      color: white;
      height: 45px;
      width: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      outline: none;
      font-size: 16px;
    }

    .accept-button[disabled],
    .reject-button[disabled] {
      opacity: 0.6;
      color: #888;
      cursor: not-allowed;
    }

    .reject-button {
      background: #444;
    }
  }
}
