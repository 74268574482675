.DashboardContainer {
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  padding: 20px;
}
.title_Dashboard h4 {
  margin-bottom: 0px;
  font-size: 40px;
}

.infoGeneralContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  margin: 1rem 2rem;
  padding: 1rem 0rem 1rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
}

.generalInf {
  list-style: none;
  font-size: 25px;
  span {
    margin: 0px 10px;
  }
}

.infStaffs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 2rem 2rem;
}

.infStaff {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 10rem;
  justify-content: space-around;
  p {
    font-size: 18px;
  }
}

.barStatus {
  margin-top: 4rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
}

.tickets-container {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  gap: 20px;
}

.Check {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border: 1px solid #14113f;
  border-radius: 5px;
  background-color: #261a3a;
  justify-content: space-between;
}

.Check-info {
  display: grid;
  grid-template-columns: 2px repeat(10, 1fr);
  width: 100%;
  align-items: center;
  justify-items: center;
  text-align: center;
  align-content: center;
}
.Check-description {
  width: 100%;
  text-align: center;
}

.Check-photo {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}
.Check-actions {
  display: flex;
  width: 110px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.Check-actions button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: white;
}

.Check-actions button:hover {
  background-color: #5a1fd1;
}

.tooltip .tooltip-content {
  visibility: hidden;
  display: inline-block;
  padding: 10px;
  background-color: #333;
  color: #fff;
  position: absolute;
  z-index: 1;
  margin-top: 20px;
}

.tooltip:hover .tooltip-content {
  visibility: visible;
}

.Check-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border: 1px solid #261a3a;
  border-radius: 11px;
  background-color: #14113f;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.Check-info-header {
  display: grid;
  grid-template-columns: 2px repeat(10, 1fr);
  width: 100%;
  align-items: center;
  justify-items: center;
  align-content: center;
}

.dashboard-search-section-CheckPoint {
  display: flex;
  gap: 1rem;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  margin: 1rem 1rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
}

.dashboard-search-bar-container {
  display: flex;
  align-items: center;
  background: rgba(80, 74, 87, 1) !important;
  width: 180px;
  height: 20px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: auto;

  .dashboard-search-icon {
    margin-right: 8px;
  }

  .dashboard-search-input {
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    width: 170px;

    &:focus {
      outline: none;
    }
  }

  &:focus-within {
    .dashboard-search-input {
      border: none;
    }
  }
}

.custom-tooltip {
  height: 600px;
}

.Check-progress {
  display: flex;
}

.Check-progress-svg,
.Check-namePlan,
.Check-status,
.Check-progress,
.Check-nameStaff,
.Check-nameLastPlan,
.Check-AddressLastPlan,
.Check-lastUpdateTime,
.Check-TimeOut,
.Check-actions,
.Check-lastPhoto,
.Check-photo {
  font-size: 14px;
}

.Check-progress-svg,
.Check-namePlan,
.Check-status,
.Check-progress,
.Check-nameStaff,
.Check-nameLastPlan,
.Check-AddressLastPlan,
.Check-lastUpdateTime,
.Check-photo {
  font-size: 14px;
}

.Check-progress-svg {
  grid-column: span 0; /* La celda ocupará dos columnas */
  width: 20px;
}

.IconSvgCheck span.anticon.icon {
  margin: 0px;
}
