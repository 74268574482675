.lock-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
  .lock-icon {
    font-size: 35px;
    color: #f40083;
    cursor: pointer;
  }
}
