.modal {
  width: 90%;
  max-width: 1100px;
  height: 100%;
  max-height: 85vh;
  overflow-y: auto;
  background: #2a1744;
  border-radius: 53px;
  position: relative;
  padding: 1rem 0;
  color: antiquewhite;

  .modal-header {
    text-align: center;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .CheckPoint-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 1rem 1rem 2rem;

    .photoStaff {
      padding-right: 10px;
    }

    .checkPoint-photo {
      width: 150px !important;
      height: 150px !important;
      object-fit: cover;
      border-radius: 50%;
      background-color: rgb(255, 255, 255);
    }

    .CheckPoint-dataHeader {
      height: 150px;
      border-left: 7px solid #c89df0;
      padding-left: 10px;
      align-content: center;
    }
  }

  p {
    width: 45%;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 auto;
  }

  span {
    font-weight: bold;
    color: #dabdf5;
  }
}

.center {
  margin-top: 1rem;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  margin: 1rem auto 3rem;

  p {
    margin: 0 auto;
    width: 80%;
  }
}

.logs-timeline {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.log-entry {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
}

.textRight {
  width: 30%;
  text-align: right;
}
.separate {
  width: 5%;
}
.dataLogs {
  width: 30%;
}

.separateTicketPhoto {
  display: flex;
  justify-content: center;
  z-index: 5;
}
.separateTicketPhotoLast {
  display: flex;
  justify-content: center;
  z-index: 5;
}
.separateTicketPhoto::after {
  content: '';
  position: absolute;
  height: 7rem;
  background-color: #c8cadb;
  /*   bottom: 6rem; */
  width: 5px;
  z-index: -1;
}

.separate {
  z-index: 5;
}

.ticket-photo {
  background-color: rgb(255, 255, 255);
}

.centerSelectStaff .select-container2 {
  border-color: #5a1fd1;
  background-color: transparent;
  border-radius: 11px;
  font-size: 14px;
  margin-top: 10px;
}

.centerSelectStaff {
  margin-top: 1rem;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  margin: 1rem auto 1rem;

  p {
    margin: 0 auto;
    width: 80%;
  }
}

.modalSelectStaff {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.modal-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 200%; /* Espacio para ambos formularios */
}

.step {
  flex: 0 0 50%;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;

  .custom-button {
    margin-top: 1rem;
  }
}

/* Animación hacia la izquierda */
.slide-left {
  transform: translateX(-50%);
}

/* Animación hacia la derecha */
.slide-right {
  transform: translateX(0);
}

.WaringModal {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: nowrap;
  align-content: center;
  height: 100%;

  .waring-text {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  .Waring-Buttons {
    display: flex;
  }
}
